import { createAction, props } from '@ngrx/store';
import { SettlementsTableVM } from './settlements.reducer';

export const settlementsInit = createAction(
  '[Settlements] Init',
  props<{ partnerCompanyId: string; }>()
);

export const settlementsDestroy = createAction(
  '[Settlements] Destroy'
);

export const dataChanged = createAction(
  '[Settlements] Settlements data changed',
  props<{ tableVM: SettlementsTableVM, totalCount: number; }>()
);

export const dateFilterChanged = createAction(
  '[Settlements] Date filter changed',
  props<{ startDate: string | null; endDate: string | null; }>()
);

export const downloadClicked = createAction(
  '[Settlements] Download clicked',
  props<{ settlementId: string; }>()
);

export const singleSettlementDownloadCsvClicked = createAction(
  '[Settlements] Single settlement download CSV clicked',
  props<{ settlementId: string; }>()
);

export const multipleSettlementsDownloadCsvClicked = createAction(
  '[Settlements] Multiple settlement download CSV clicked'
);

export const downloadSuccess = createAction(
  '[Settlements] Download success'
);

export const downloadFailed = createAction(
  '[Settlements] Download failed'
);

export const pageEvent = createAction(
  '[Settlements] Page event',
  props<{ pageSize: number; pageIndex: number; previousPageIndex?: number }>()
);
