import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthSelectors } from 'src/app/auth/store';
import { AppSelectors } from 'src/app/store';
import * as fromDashboard from './dashboard.reducer';
import { entityAdapter } from './dashboard.reducer';

export const selectDashboardState = createFeatureSelector<fromDashboard.State>(
  fromDashboard.dashboardFeatureKey
);

export const selectPartnerCompanyId = createSelector(selectDashboardState, state => state.partnerCompanyId);
export const selectPartnerCompanyAggregate = createSelector(AuthSelectors.selectPartnerCompanyAggregates, selectPartnerCompanyId,
  (partnerCompanyAggregates, companyId) => partnerCompanyAggregates?.find(pc => pc.id === companyId)
);
export const selectPartnerCompanyArea = createSelector(AppSelectors.selectAreas, selectPartnerCompanyAggregate,
  (areas, partnerCompany) => areas.find(area => area.id === partnerCompany?.company?.area_id)
);
export const selectDriverAggregates = createSelector(selectDashboardState, state => state.driverAggregates);
export const selectIsProgressBarVisible = createSelector(selectDashboardState, state => state.isLoadingDataSource);
export const selectIsPaginatorDisabled = createSelector(selectIsProgressBarVisible, isProgressBarVisible => isProgressBarVisible);
export const selectPaginatorLength = createSelector(selectDashboardState, state => state.totalRowsCount);
export const selectPageIndex = createSelector(selectDashboardState, state => state.pagination.pageIndex);
export const selectPageSize = createSelector(selectDashboardState, state => state.pagination.pageSize);
export const selectDateFilter = createSelector(selectDashboardState, state => state.dateFilter);
export const selectDriverFilterOptions = createSelector(selectDriverAggregates, driverAggregates =>
  driverAggregates
    ? driverAggregates.map(agg => ({
      value: agg.id,
      display: agg.driver.first_name + ' ' + agg.driver.last_name
    })).sort((a, b) => a.display.localeCompare(b.display))
    : []
);
export const selectDriverFilterValue = createSelector(selectDashboardState, state => state.driverFilterValue);
export const selectPagination = createSelector(selectDashboardState, state => state.pagination);
export const selectDataSourceParameters = createSelector(selectDateFilter, selectDriverFilterValue, selectPagination, (dateFilter, driverIdFilter, pagination) =>
({
  startDate: dateFilter.startDate,
  endDate: dateFilter.endDate,
  driverId: driverIdFilter,
  pageSize: pagination.pageSize,
  pageIndex: pagination.pageIndex,
  previousPageIndex: pagination.previousPageIndex
})
);

const { selectAll } = entityAdapter.getSelectors();
export const selectTableRows = createSelector(selectDashboardState, (state) => state.tableRows ? selectAll(state.tableRows) : null);
export const selectTableFooter = createSelector(selectDashboardState, (state) => state.footer);
