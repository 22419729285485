import { createReducer, on } from '@ngrx/store';
import * as SettlementsActions from './settlements.actions';

export const settlementsFeatureKey = 'settlements';

export interface SettlementsTableVM {
  rows: SettlementsTableRow[] | null;
  footer: SettlementsTableRow | null; // not currently implemented for settlements
}

export interface SettlementsTableRow {
  id: string;
  created: string;
  period: string;
  totalRevenue: string;
  settlement: string;
  payout: string;
}

export interface State {
  dateFilter: { startDate: string | null, endDate: string | null };
  tableVM: SettlementsTableVM;
  isDataSourceLoading: boolean;
  isDownloadInProgress: boolean;
  partnerCompanyId: string | null;
  totalRowsCount: number;
  pagination: {
    pageSize: number,
    pageIndex: number,
    previousPageIndex?: number
  };
}

export const initialState: State = {
  dateFilter: { startDate: null, endDate: null },
  tableVM: {
    rows: null,
    footer: null
  },
  isDataSourceLoading: false,
  isDownloadInProgress: false,
  partnerCompanyId: null,
  totalRowsCount: 0,
  pagination: {
    pageSize: 25,
    pageIndex: 0,
    previousPageIndex: undefined
  }
};

export const reducer = createReducer(
  initialState,

  on(SettlementsActions.settlementsInit, (state, { partnerCompanyId }): State => ({
    ...initialState,
    partnerCompanyId
  })),

  on(SettlementsActions.dataChanged, (state, action): State => ({
    ...state,
    tableVM: action.tableVM,
    isDataSourceLoading: false,
    totalRowsCount: action.totalCount
  })),

  on(SettlementsActions.downloadClicked, (state): State => ({
    ...state,
    isDownloadInProgress: true
  })),

  on(SettlementsActions.singleSettlementDownloadCsvClicked, (state): State => ({
    ...state,
    isDownloadInProgress: true
  })),

  on(SettlementsActions.multipleSettlementsDownloadCsvClicked, (state): State => ({
    ...state,
    isDownloadInProgress: true
  })),

  on(SettlementsActions.downloadSuccess, (state): State => ({
    ...state,
    isDownloadInProgress: false
  })),

  on(SettlementsActions.downloadFailed, (state): State => ({
    ...state,
    isDownloadInProgress: false
  })),

  on(SettlementsActions.dateFilterChanged, (state, action): State => ({
    ...state,
    dateFilter: { startDate: action.startDate, endDate: action.endDate },
    pagination: {
      pageSize: state.pagination.pageSize,
      pageIndex: 0,
      previousPageIndex: undefined
    },
    isDataSourceLoading: true
  })),

  on(SettlementsActions.pageEvent, (state, action): State => ({
    ...state,
    isDataSourceLoading: true,
    pagination: {
      pageSize: action.pageSize,
      pageIndex: action.pageIndex,
      previousPageIndex: action.previousPageIndex
    }
  })),
);
