import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DriverAggregate } from 'src/app/core/models/firestore.model';
import { DailyEarningsFooterRow } from 'src/app/daily-earnings/models/daily-earnings-footer-row';
import { DailyEarningsRow } from 'src/app/daily-earnings/models/daily-earnings-row';
import * as DashboardActions from './dashboard.actions';

export const dashboardFeatureKey = 'dashboard';

export interface DashboardTableRow extends DailyEarningsRow {
  driver: string;
}

export interface DashboardTableFooterRow extends DailyEarningsFooterRow {
  driver: string;
}

export interface State {
  dateFilter: { startDate: string | null, endDate: string | null };
  driverAggregates: DriverAggregate[] | undefined;
  driverFilterValue: string;
  tableRows: EntityState<DashboardTableRow> | null;
  footer: DashboardTableFooterRow | null;
  partnerCompanyId: string | null;
  isLoadingDataSource: boolean;
  totalRowsCount: number;
  pagination: {
    pageSize: number,
    pageIndex: number,
    previousPageIndex?: number
  };
}

export const entityAdapter: EntityAdapter<DashboardTableRow> =
  createEntityAdapter<DashboardTableRow>({});

export const initialState: State = {
  dateFilter: { startDate: null, endDate: null },
  driverAggregates: undefined,
  driverFilterValue: '',
  tableRows: null,
  footer: null,
  partnerCompanyId: null,
  isLoadingDataSource: false,
  totalRowsCount: 0,
  pagination: {
    pageSize: 25,
    pageIndex: 0,
    previousPageIndex: undefined
  }
};

export const reducer = createReducer(
  initialState,

  on(DashboardActions.dashboardInit, (state, { partnerCompanyId }): State => ({
    ...initialState,
    partnerCompanyId
  })),

  on(DashboardActions.dateFilterChanged, (state, action): State => {
    return {
      ...state,
      dateFilter: { startDate: action.startDate, endDate: action.endDate },
      pagination: {
        pageSize: state.pagination.pageSize,
        pageIndex: 0,
        previousPageIndex: undefined
      },
      isLoadingDataSource: true
    }
  }),

  on(DashboardActions.dataChanged, (state, action): State => ({
    ...state,
    tableRows: entityAdapter.setAll(action.rows, state.tableRows || entityAdapter.getInitialState()),
    footer: action.footer,
    isLoadingDataSource: false,
    totalRowsCount: action.totalCount
  })),

  on(DashboardActions.pageEvent, (state, action): State => ({
    ...state,
    isLoadingDataSource: true,
    pagination: {
      pageSize: action.pageSize,
      pageIndex: action.pageIndex,
      previousPageIndex: action.previousPageIndex
    }
  })),

  on(DashboardActions.driverFilterChanged, (state, action): State => ({
    ...state,
    driverFilterValue: action.driverId,
    pagination: {
      pageSize: state.pagination.pageSize,
      pageIndex: 0,
      previousPageIndex: undefined
    },
    isLoadingDataSource: true
  })),

  on(DashboardActions.driverAggregatesChanged, (state, action): State => ({
    ...state,
    driverAggregates: action.driverAggregates
  })),

  on(DashboardActions.rowExpandClicked, (state, action): State => {
    return {
      ...state,
      tableRows: entityAdapter.updateOne({
        id: action.row.id, changes: {
          isExpanded: !state.tableRows?.entities[action.row.id]?.isExpanded,
        }
      }, state.tableRows || entityAdapter.getInitialState())
    };
  }),

  on(DashboardActions.detailRowsFetched, (state, action): State => ({
    ...state,
    tableRows: entityAdapter.updateOne({
      id: action.rowId, changes: {
        detailRows: action.detailRows
      }
    }, state.tableRows || entityAdapter.getInitialState())
  })),

);
