import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSettlements from './settlements.reducer';

export const selectSettlementsState = createFeatureSelector<fromSettlements.State>(
  fromSettlements.settlementsFeatureKey
);
export const selectTableVM = createSelector(selectSettlementsState, state => state.tableVM);
export const selectIsDataSourceLoading = createSelector(selectSettlementsState, state => state.isDataSourceLoading);
export const selectIsDownloadInProgress = createSelector(selectSettlementsState, state => state.isDownloadInProgress);
export const selectIsProgressBarVisible = createSelector(selectIsDataSourceLoading, selectIsDownloadInProgress,
  (isDataSourceLoading, isDownloadInProgress) => isDataSourceLoading || isDownloadInProgress
);
export const selectPartnerCompanyId = createSelector(selectSettlementsState, state => state.partnerCompanyId);
export const selectIsPaginatorDisabled = createSelector(selectIsProgressBarVisible, isProgressBarVisible => isProgressBarVisible);
export const selectPaginatorLength = createSelector(selectSettlementsState, state => state.totalRowsCount);
export const selectPageIndex = createSelector(selectSettlementsState, state => state.pagination.pageIndex);
export const selectPageSize = createSelector(selectSettlementsState, state => state.pagination.pageSize);
export const selectDateFilter = createSelector(selectSettlementsState, state => state.dateFilter);
export const selectPagination = createSelector(selectSettlementsState, state => state.pagination);
export const selectDataSourceParameters = createSelector(selectDateFilter, selectPagination, (dateFilter, pagination) =>
({
  startDate: dateFilter.startDate,
  endDate: dateFilter.endDate,
  pageSize: pagination.pageSize,
  pageIndex: pagination.pageIndex,
  previousPageIndex: pagination.previousPageIndex
})
);
export const selectTableVMIds = createSelector(selectTableVM, table => table.rows ? table.rows .map(row => row.id) : []);
