import { inject, Injectable } from '@angular/core';
import { collection, Firestore, orderBy, query, QueryConstraint, where } from '@angular/fire/firestore';
import { map, Observable, take } from 'rxjs';
import {
  CreditNote,
  DailyEarningsSplit,
  DriverAggregate,
  Settlement,
  Shift,
  Vehicle
} from 'src/app/core/models/firestore.model';
import { DateRange } from 'src/app/shared/models/date-range.vm';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { FirestoreHelperService, Pagination } from 'src/app/shared/services/firestore-helper.service';

interface FilterWithDriverIds extends DateRange {
  driverIds: string[];
}

interface FilterWithDriverId extends DateRange {
  driverId: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class PartnerFirestoreService {
  private firestore = inject(Firestore);
  private firestoreHelper = inject(FirestoreHelperService);
  private dateHelper = inject(DateHelperService);

  constructor(private dateHelperService: DateHelperService) { }

  getPartnerSettlement(partnerCompanyId: string, settlementId: string): Observable<Settlement | null> {
    return this.firestoreHelper.getDocument<Settlement>(`partner_company_aggregate/${partnerCompanyId}/settlement`, settlementId);
  }

  getPartnerSettlements(
    partnerCompanyId: string,
    filter: DateRange
  ): Observable<Map<string, Settlement | null>> {
    const constraints = this.getSettlementsQuery(filter);

    const collectionPath = `partner_company_aggregate/${partnerCompanyId}/settlement`;
    const colRef = collection(this.firestore, collectionPath);
    const q = query(colRef, ...constraints);

    return this.firestoreHelper.getDocumentsByQuery<Settlement>(q);
  }

  getVehicles(vehicleIds: string[]): Observable<Map<string, Vehicle | null>> {
    return this.firestoreHelper.getDocumentsByIds<Vehicle>(`vehicle`, vehicleIds);
  }

  watchPartnerCompanySettlements(
    partnerCompanyId: string,
    filter: DateRange,
    pagination: Pagination
  ) {
    const constraints = this.getSettlementsQuery(filter);

    const collectionPath = `partner_company_aggregate/${partnerCompanyId}/settlement`;
    const colRef = collection(this.firestore, collectionPath);
    const q = query(colRef, ...constraints);

    return this.firestoreHelper.colDataPageable<Settlement>(q, collectionPath, pagination);
  }

  watchPartnerCompanyShifts(
    partnerCompanyId: string,
    filter: FilterWithDriverIds,
    pagination: Pagination
  ) {
    const constraints = this.getShiftsQuery(filter);
    const collectionPath = `partner_company_aggregate/${partnerCompanyId}/shift`;
    const colRef = collection(this.firestore, collectionPath);
    const q = query(colRef, ...constraints);

    return this.firestoreHelper.getDocsPageable<Shift>(q, collectionPath, pagination);
  }

  getPartnerCompanyShifts(
    partnerCompanyId: string,
    filter: FilterWithDriverIds
  ) {
    const constraints = this.getShiftsQuery(filter);
    const collectionPath = `partner_company_aggregate/${partnerCompanyId}/shift`;
    const colRef = collection(this.firestore, collectionPath);
    const q = query(colRef, ...constraints);

    return this.firestoreHelper.getDocumentsByQuery<Shift>(q);
  }

  listDailyEarningsSplits(
    partnerCompanyId: string,
    filter: FilterWithDriverId,
    pagination: Pagination
  ) {
    const constraints = this.getPartnerDailyEarningsQueryConstraints(filter);

    const collectionPath = `partner_company_aggregate/${partnerCompanyId}/daily_earnings_split`;
    const colRef = collection(this.firestore, collectionPath);
    const q = query(colRef, ...constraints);

    return this.firestoreHelper.getDocsPageable<DailyEarningsSplit>(q, collectionPath, pagination);
  }

  getDailyEarningsSplits(
    partnerCompanyId: string,
    filter: FilterWithDriverId
  ) {
    const constraints = this.getPartnerDailyEarningsQueryConstraints(filter);

    const collectionPath = `partner_company_aggregate/${partnerCompanyId}/daily_earnings_split`;
    const colRef = collection(this.firestore, collectionPath);
    const q = query(colRef, ...constraints);

    return this.firestoreHelper.getDocumentsByQuery<DailyEarningsSplit>(q);
  }

  watchDriverAggregates(driverIds: string[]) {
    return this.firestoreHelper.colDataByIdsNotNull<DriverAggregate>(`driver_aggregate`, driverIds);
  }

  getSettlementCreditNote(partnerCompanyId: string, settlementId: string) {
    const colRef = collection(this.firestore, `partner_company_aggregate/${partnerCompanyId}/credit_note`);
    const q = query(colRef, where('settlement_id', '==', settlementId));
    return this.firestoreHelper.colData<CreditNote>(q).pipe(
      map(creditNotes =>
        creditNotes?.length > 0
          ? creditNotes[0]
          : null
      ),
      take(1)
    );
  }

  private getPartnerDailyEarningsQueryConstraints(
    filter: FilterWithDriverId
  ): QueryConstraint[] {
    const constraints: QueryConstraint[] = [orderBy('local_date', 'desc')];
    if (filter.startDate) {
      constraints.push(where('local_date', '>=', this.dateHelper.parseDateStrToLocalDate(filter.startDate)));
    }
    if (filter.endDate) {
      constraints.push(where('local_date', '<=', this.dateHelper.parseDateStrToLocalDate(filter.endDate)));
    }
    if (filter.driverId) {
      constraints.push(where('driver_id', '==', filter.driverId));
    }
    return constraints;
  }

  private getSettlementsQuery(
    filter: DateRange
  ): QueryConstraint[] {
    const constraints: QueryConstraint[] = [orderBy('local_start_date', 'desc')];
    if (filter.startDate) {
      constraints.push(where('local_start_date', '>=', this.dateHelper.parseDateStrToLocalDate(filter.startDate)));
    }
    if (filter.endDate) {
      constraints.push(where('local_start_date', '<=', this.dateHelper.parseDateStrToLocalDate(filter.endDate)));
    }
    return constraints;
  }

  private getShiftsQuery(
    filter: FilterWithDriverIds
  ): QueryConstraint[] {
    const constraints: QueryConstraint[] = [orderBy('started_at', 'desc')];
    if (filter.startDate) {
      constraints.push(where('started_at', '>=', this.dateHelperService.parseDateStrToDate(filter.startDate)));
    }
    if (filter.endDate) {
      const endDate = this.dateHelperService.parseDateStrToDate(filter.endDate);
      endDate.setHours(23, 59, 59, 999);
      constraints.push(where('started_at', '<=', endDate));
    }
    if (filter.driverIds.length > 0) {
      constraints.push(where('driver_id', 'in', filter.driverIds));
    }
    return constraints;
  }
}
