import { createAction, props } from '@ngrx/store';
import { DriverAggregate } from 'src/app/core/models/firestore.model';
import { DailyEarningsDetailRow } from 'src/app/daily-earnings/models/daily-earnings-detail-row';
import { DailyEarningsRow } from 'src/app/daily-earnings/models/daily-earnings-row';
import { DashboardTableFooterRow, DashboardTableRow, } from './dashboard.reducer';

export const dashboardInit = createAction(
  '[Dashboard] Init',
  props<{ partnerCompanyId: string; }>()
);

export const dashboardDestroy = createAction(
  '[Dashboard] Destroy'
);

export const dataChanged = createAction(
  '[Dashboard] Data changed',
  props<{ rows: DashboardTableRow[], footer: DashboardTableFooterRow | null, totalCount: number; }>()
);

export const dateFilterChanged = createAction(
  '[Dashboard] Date filter changed',
  props<{ startDate: string | null; endDate: string | null; }>()
);

export const pageEvent = createAction(
  '[Dashboard] Page event',
  props<{ pageSize: number; pageIndex: number; previousPageIndex?: number }>()
);

export const driverFilterChanged = createAction(
  '[Dashboard] Driver filter changed',
  props<{ driverId: string; }>()
);

export const driverAggregatesChanged = createAction(
  '[Dashboard] Driver aggregates changed',
  props<{ driverAggregates: DriverAggregate[]; }>()
);

export const rowExpandClicked = createAction(
  '[Dashboard] Row expand clicked',
  props<{ row: DailyEarningsRow }>()
);

export const detailRowsFetched = createAction(
  '[Dashboard] Detail rows fetched',
  props<{ rowId: string, detailRows: DailyEarningsDetailRow[] }>()
);

export const downloadCsvClicked = createAction(
   '[Dashboard] Download CSV clicked'
);
